import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-lazy-load-image-component/src/effects/blur.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-quill/dist/quill.snow.css");
