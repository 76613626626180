import { createContext, useContext, useMemo, useState } from 'react';

import type { Comic } from '@/interfaces/comic';

type MangaProviderProps = {
  children?: React.ReactNode;
};

type MangaContextType = {
  isDetailManga: Comic | undefined;
  selectGenreId: string | undefined;
};

const MangaContext = createContext<MangaContextType>({
  isDetailManga: undefined,
  selectGenreId: undefined,
});

type MangaActionsContextType = {
  setIsDetailManga: (isDetailManga: Comic | undefined) => void;
  setSelectGenreId: (selectGenreId: string | undefined) => void;
};

const MangaActionsContext = createContext<MangaActionsContextType>({
  setIsDetailManga: () => {},
  setSelectGenreId: () => {},
});

export const useManga = () => useContext(MangaContext);

export const useMangaActions = () => useContext(MangaActionsContext);

export default function MangaProvider({ children }: MangaProviderProps) {
  const [isDetailManga, setIsDetailManga] = useState<Comic | undefined>(
    undefined
  );
  const [selectGenreId, setSelectGenreId] = useState<string | undefined>(
    undefined
  );

  return useMemo(
    () => (
      <MangaContext.Provider
        value={{
          isDetailManga,
          selectGenreId,
        }}
      >
        <MangaActionsContext.Provider
          value={{
            setIsDetailManga,
            setSelectGenreId,
          }}
        >
          {children}
        </MangaActionsContext.Provider>
      </MangaContext.Provider>
    ),
    [isDetailManga, children, selectGenreId]
  );
}
