export enum StatusComicTypes {
  NEW = 'new',
  HOT = 'hot',
  TRENDING = 'trending',
}

export const DEFAULT_PAGINATION = {
  LIMIT: 10,
  PAGE: 1,
  SORT: 'id',
  ORDER: 'DESC',
};

export const PAGE_SIZES = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const STALE_TIME = 10 * (60 * 1000);
export const CACHE_TIME = 15 * (60 * 1000);

export type UserRole = 'admin' | 'user';
export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
}

export type MaturityRating = '14' | '16' | '18';
export enum MaturityRatings {
  FOURTEEN = '14',
  SIXTEEN = '16',
  EIGHTTEEN = '18',
}

export type ReviewStatus = 'recommended' | 'not_recommended' | 'mixed_feelings';
export enum ReviewsStatus {
  RECOMMENDED = 'recommended',
  NOTRECOMMENDED = 'not_recommended',
  MIXEDFEELINGS = 'mixed_feelings',
}

export type CommentStatus = 'author' | 'viewer';
export enum CommentsStatus {
  AUTHTOR = 'author',
  VIEWER = 'viewer',
}

export type MangaOriginal = 'ongoing' | 'completed' | 'one_shot';
export enum MangaOriginals {
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
  ONESHOT = 'one_shot',
}

export type MangaStatus = 'published' | 'pending';
export enum MangaStatuses {
  PUBLISHED = 'published',
  PENDING = 'pending',
}

export type LanguageStatus = 'eng' | 'vie' | 'spn' | 'jpn';
export enum LanguageStatuses {
  ENGLISH = 'eng',
  VIETNAMESE = 'vie',
  SPANISH = 'spn',
  JAPANESE = 'jpn',
}

export const ComponentTypes = {
  PUBLIIC_MANGA: {
    LIST: 'publicManga.list',
    DETAIL: 'publicManga.detail',
    CREATE: 'publicManga.create',
    UPDATE: 'publicManga.update',
  },
  PUBLIIC_CHAPTER: {
    DETAIL: 'publicChapter.detail',
    CREATE: 'publicChapter.create',
    UPDATE: 'publicChapter.update',
  },
};
