'use client';

import { NextUIProvider } from '@nextui-org/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import type { HTTPError } from 'ky';
import { SessionProvider, signOut } from 'next-auth/react';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import LayoutProvider from '@/components/providers/LayoutProvider';
import MangaProvider from '@/components/providers/MangaProvider';
import { CACHE_TIME, STALE_TIME } from '@/libs/constants';

type Props = {
  children?: React.ReactNode;
};

export default function Provider({ children }: Props) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          staleTime: STALE_TIME,
          gcTime: CACHE_TIME,
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          if (error.name === 'HTTPError') {
            const errJson = (error as HTTPError).response;

            if (errJson.status === 401) {
              signOut();
            }
          }
        },
      }),
    })
  );
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <NextUIProvider>
          <LayoutProvider>
            <MangaProvider>
              {children}
              <ToastContainer />
            </MangaProvider>
          </LayoutProvider>
        </NextUIProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
}
